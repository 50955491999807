<template>
  <ul v-if="items" role="menu">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator"
        :key="item.label || i"
        :class="[
          {
            'layout-root-menuitem': root,
            'active-menuitem': activeIndex === i && !item.disabled,
          },
        ]"
        role="menuitem"
      >
        <div v-if="root && item.visible && item.visible() !== false">
          <span class="layout-menuitem-text font-bold text-base text-color">{{
            item.label
          }}</span>
        </div>
        <NuxtLink
          v-if="item.to"
          v-wave
          :to="item.to"
          active-class="active-route"
          @click="
            (e) => {
              if (appConfigStore.isMobile) {
                appConfigStore.sidebarVisible = false;
              }
            }
          "
          :class="[item.class, { 'p-disabled': item.disabled }]"
        >
          <i
            v-if="item.icon?.includes('pi ')"
            :class="['layout-menuitem-icon mr-2 text-color', item.icon]"
          ></i>
          <AppIcon
            v-else
            :name="item.icon!"
            size="14"
            class="layout-menuitem-icon mr-2 text-color pi pi-fw"
            :class="[item.icon]"
          />
          {{ item.label }}
        </NuxtLink>
        <a
          v-else-if="!item.to && !root"
          v-wave
          @click="onMenuItemClick($event, item, i)"
        >
          <i :class="['layout-menuitem-icon mr-2', item.icon]" />
          {{ item.label }}
        </a>
        <AppSubmenu
          v-show="item.items"
          :items="visible(item) && item.items"
          :menuActive="menuActive"
          :parentMenuItemActive="activeIndex === i"
          @menuitem-click="$emit('menuitem-click', $event)"
        />
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import type { MenuItem } from '~/types';

const appConfigStore = useAppConfigStore();

defineProps({
  items: {
    type: Array as PropType<MenuItem[]>,
  },
  menuActive: Boolean,
  root: {
    type: Boolean,
    default: false,
  },
  parentMenuItemActive: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['root-menuitem-click', 'menuitem-click']);

const activeIndex = ref(null);

const visible = (item: MenuItem) => {
  return typeof item.visible === 'function'
    ? item.visible()
    : item.visible !== false;
};

const onMenuItemClick = (event: any, item: any, index: any) => {
  if (item.disabled) {
    event.preventDefault();
    return;
  }

  if (item.command) {
    item.command({ originalEvent: event, item: item });
    event.preventDefault();
  }

  if (item.items) {
    event.preventDefault();
  }
};
</script>
