<template>
  <div class="layout-topbar">
    <div class="layout-topbar-wrapper justify-content-between">
      <div style="flex-grow: 1; flex-basis: 0" class="ml-2">
        <IconButton
          @click="toggleMenu"
          :iconName="
            appConfigStore.sidebarVisible
              ? 'mingcute:close-fill'
              : 'i-ci:hamburger-lg'
          "
        />
      </div>
      <div style="height: 100%">
        <NuxtLink to="/" class="justify-content-start h-full">
          <div
            class="layout-topbar-logo cursor-pointer outline-none ml-1 sm:ml-3 h-full"
            id="logolink"
          >
            <img
              id="app-logo"
              alt="poseidon-layout"
              src="assets/images/logo_full.svg"
              class="h-full w-full"
            />
          </div>
        </NuxtLink>
      </div>
      <div style="flex-grow: 1; flex-basis: 0">
        <div class="layout-topbar-right px-2 sm:px-4">
          <ul class="layout-topbar-actions">
            <li
              class="topbar-item notifications"
              :class="{
                'active-topmenuitem': activeTopbarItem === 'notifications',
              }"
            >
              <IconButton
                @click="changeColorMode"
                :iconName="
                  appConfigStore.colorMode === 'dim'
                    ? 'i-material-symbols:dark-mode-outline'
                    : 'i-material-symbols:light-mode-outline'
                "
              />
            </li>
            <li
              class="topbar-item user-profile"
              :class="{ 'active-topmenuitem': activeTopbarItem === 'profile' }"
            >
              <a href="#" @click="onTopbarItemClick($event, 'profile')">
                <img
                  class="profile-image border-circle"
                  :src="
                    authStore.avatarUrl ||
                    `https://ui-avatars.com/api/?name=${authStore.name}`
                  "
                  alt="demo"
                />
                <div class="profile-info white-space-nowrap">
                  <h6>{{ authStore.name }}</h6>
                  <span>{{ toSentenceCase(authStore.role) }}</span>
                </div>
              </a>
              <ul class="fadeInDown">
                <li
                  role="menuitem"
                  @click="router.push('/account/profile')"
                  class="flex align-items-center"
                >
                  <AppIcon
                    name="i-material-symbols:account-circle"
                    size="24"
                    v-badge="1"
                    class="mr-1"
                  />
                  <h6>Profile</h6>
                </li>
                <li
                  role="menuitem"
                  @click="handleLogout()"
                  class="flex align-items-center"
                >
                  <AppIcon
                    name="i-material-symbols:logout"
                    size="24"
                    v-badge="1"
                    class="mr-1"
                  />
                  <h6>Logout</h6>
                </li>
              </ul>
            </li>
          </ul>
          <!-- todo: right pannel switch -->
          <!-- <IconButton
          iconName="i-ph:arrow-left-bold"
          @click="$emit('right-panel-click', $event)"
        /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const router = useRouter();

defineProps({
  activeTopbarItem: String,
  menuTheme: String,
});

const emits = defineEmits([
  'right-panel-click',
  'topbar-item-click',
  'search-click',
]);

const {
  $auth: { logOut },
} = useNuxtApp();
const appConfigStore = useAppConfigStore();
const { animateColorMode } = useAnime();

const changeColorMode = async (event: Event) => {
  animateColorMode(appConfigStore.colorMode);
  event.preventDefault();
};

const toggleMenu = (event: Event) => {
  appConfigStore.sidebarVisible = !appConfigStore.sidebarVisible;
  event.preventDefault();
};

const onTopbarItemClick = (event: Event, item: string) => {
  emits('topbar-item-click', { originalEvent: event, item: item });
  event.preventDefault();
};

const handleLogout = async () => {
  await logOut();
  return navigateTo('/login');
};
</script>
