<template>
  <div :class="containerClass" @click="onDocumentClick()">
    <AppTopbar
      :activeTopbarItem="activeTopbarItem"
      @menu-button-click="onMenuButtonClick($event)"
      @right-panel-click="onRightPanelButtonClick"
      @topbar-item-click="onTopbarItemClick"
      @search-click="onSearchClick"
    ></AppTopbar>

    <!-- todo: right panel -->
    <!-- <AppRightPanel @right-panel-click="onRightPanelClick"></AppRightPanel> -->

    <div class="menu-wrapper" @click="onMenuClick()">
      <AppMenu />
    </div>
    <div class="layout-main">
      <div class="layout-content">
        <slot />
      </div>
    </div>
    <div
      v-if="appConfigStore.isMobile"
      class="layout-mask modal-in"
      @click="appConfigStore.sidebarVisible = false"
    ></div>
  </div>
</template>

<script setup lang="ts">
import EventBus from '~/event-bus';

const appConfigStore = useAppConfigStore();
const runtimeConfig = useRuntimeConfig();

const configActive = ref(false);
const configClick = ref(false);
const menuMode = ref('static');
const overlayMenuActive = ref(false);
const staticMenuMobileActive = ref(false);
const rightPanelActive = ref(false);
const activeTopbarItem = ref(undefined);
const topbarMenuActive = ref(false);
const menuClick = ref(false);
const rightPanelClick = ref(false);
const topbarItemClick = ref(false);
const searchActive = ref(false);
const searchClick = ref(false);
const menuActive = ref(false);
const userMenuClick = ref(false);

const onDocumentClick = () => {
  if (!searchClick.value) {
    searchActive.value = false;
  }

  if (!topbarItemClick.value) {
    activeTopbarItem.value = undefined;
    topbarMenuActive.value = false;
  }

  if (!userMenuClick.value) {
    topbarMenuActive.value = false;
  }

  if (!rightPanelClick.value) {
    rightPanelActive.value = false;
  }

  if (!menuClick.value) {
    if (isHorizontal()) {
      EventBus.emit('reset-active-index');
      menuActive.value = false;
    }

    if (overlayMenuActive.value || staticMenuMobileActive.value) {
      hideOverlayMenu();
    }

    unblockBodyScroll();
  }

  if (configActive.value && !configClick.value) {
    configActive.value = false;
  }

  searchClick.value = false;
  configClick.value = false;
  topbarItemClick.value = false;
  rightPanelClick.value = false;
  menuClick.value = false;
};

const onMenuClick = () => {
  menuClick.value = true;
};

const hideOverlayMenu = () => {
  overlayMenuActive.value = false;
  staticMenuMobileActive.value = false;
  unblockBodyScroll();
};

// const onRightPanelClick = () => {
//   rightPanelClick.value = true;
// };
const onRightPanelButtonClick = (event: { preventDefault: () => void }) => {
  rightPanelClick.value = true;
  rightPanelActive.value = !rightPanelActive.value;
  event.preventDefault();
};

const onTopbarItemClick = (event: any) => {
  topbarItemClick.value = true;

  if (activeTopbarItem.value === event.item) {
    activeTopbarItem.value = undefined;
  } else {
    activeTopbarItem.value = event.item;
  }

  if (event.item === 'search') {
    searchActive.value = !searchActive.value;
    searchClick.value = !searchClick.value;
  }

  event.originalEvent.preventDefault();
};

const onSearchClick = (event: { stopPropagation: () => void }) => {
  searchClick.value = true;
  event.stopPropagation();
};

const onMenuButtonClick = (event: { preventDefault: () => void }) => {
  topbarMenuActive.value = false;
  menuClick.value = true;

  if (isOverlay() && !isMobile()) {
    overlayMenuActive.value = !overlayMenuActive.value;
  }

  if (isDesktop()) {
    appConfigStore.sidebarVisible = !appConfigStore.sidebarVisible;
  } else {
    staticMenuMobileActive.value = !staticMenuMobileActive.value;
    if (staticMenuMobileActive.value) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }

  event.preventDefault();
};

const blockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.add('blocked-scroll');
  } else {
    document.body.className += ' blocked-scroll';
  }
};

const unblockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.remove('blocked-scroll');
  } else {
    document.body.className = document.body.className.replace(
      new RegExp(
        '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
        'gi'
      ),
      ' '
    );
  }
};

const isDesktop = () => {
  return window.innerWidth > 992;
};

const isMobile = () => {
  return window.innerWidth <= 640;
};

const isOverlay = () => {
  return menuMode.value === 'overlay';
};

const isHorizontal = () => {
  return menuMode.value === 'horizontal';
};

const containerClass = computed(() => {
  return [
    'layout-wrapper',
    {
      'layout-overlay': menuMode.value === 'overlay',
      'layout-static': menuMode.value === 'static',
      'layout-horizontal': menuMode.value === 'horizontal',
      'layout-overlay-active': overlayMenuActive.value,
      'layout-mobile-active': appConfigStore.sidebarVisible,
      'layout-static-active':
        appConfigStore.sidebarVisible && menuMode.value === 'static',
      'layout-rightpanel-active': rightPanelActive.value,
      'p-input-filled':
        runtimeConfig.public.primevue.options.inputStyle === 'filled',
    },
    'layout-menu-' +
      appConfigStore.colorMode +
      ' layout-topbar-' +
      appConfigStore.colorMode,
  ];
});
</script>
